<template>
	<div>
		<template v-if="loadingOrders">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header" v-if="orderRoute">
				<div class="main__primary">
					<h3>Order</h3>
				</div>
				<div class="form__search-wrapper">
					<label class="form__label" for="search-user">
						<input
							id="search-user"
							class="form-control form__input form__search"
							type="search"
							v-model="query"
							@keyup.enter="search"
							placeholder="Type to search for an Order"
						/>
						<span class="form__search__icon">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<title>Search Icon</title>
								<path
									d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 
									9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 
									0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
									fill="#828282"
								/>
							</svg>
						</span>
					</label>
					<small class="error-feedback">{{ queryError }}</small>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<div></div>
						<p class="info__address">Stock</p>
						<p class="info__address">Investor</p>
						<p class="info__address">Payment</p>
						<p class="info__address">Amount</p>
						<p class="info__address">QTY</p>
						<p class="info__address">Status</p>
						<p class="info__address">Action</p>
					</div>
					<div class="contacts__body">
						<template v-if="ordersLoaded && searchLoaded && orders.length > 0">
							<OrderItem v-for="order in orders" :key="order.id" :orderData="order" />
						</template>
						<template v-else-if="!searchLoaded && getsearchOrders.length > 0">
							<OrderItem v-for="order in getsearchOrders" :key="order.id" :orderData="order" />
						</template>
						<template v-else-if="!searchLoaded && getsearchOrders.length === 0">
							<p class="mt-5 pl-5">No Order found</p>
						</template>
						<template v-else-if="ordersLoaded">
							<p class="mt-5 pl-5">No Order found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading orders, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import OrderItem from './OrderItem';

export default {
	name: 'Order',
	components: {
		Loader,
		Pagination,
		OrderItem
	},
	data() {
		return {
			ordersLoaded: false,
			loadingOrders: true,
			offset: 0,
			limit: 10,
			baseUrl: 'orders',
			query: '',
			searchLoaded: true,
			total: 0,
			queryError: '',
			orderRoute: false
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllOrders([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (this.$route.name === 'orders') {
				this.orderRoute = true;
			}
			if (value === 'success') {
				this.loading = false;
				this.loadingOrders = false;
				this.query = '';
				this.ordersLoaded = true;
				this.total = this.searchLoaded ? this.totalOrders : this.searchTotal;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllOrders([this.limit, this.offset]);
		},
		query() {
			this.queryError = '';
		}
	},
	computed: {
		...mapGetters({
			orders: 'getOrders',
			status: 'getStatus',
			totalOrders: 'getTotalOrders',
			getsearchOrders: 'getOrdersSearch'
		})
	},
	methods: {
		...mapActions(['fetchAllOrders', 'searchOrders']),
		search() {
			if (this.query.length === 0) {
				this.fetchAllOrders([this.limit, this.offset]);
				this.searchLoaded = true;
			} else if (this.query.length < 3) {
				this.queryError = 'Search parameter must be at least 3 characters long';
			} else {
				this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
				this.offset =
					this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
				const data = { limit: this.limit, offset: this.offset, query: this.query };
				this.searchOrders(data);
				this.searchLoaded = false;
				this.total = this.searchTotal;
				this.queryError = '';
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 40px 2.2fr 1.3fr 1fr 1fr 1fr 1fr 1fr 0.8fr;
}
</style>

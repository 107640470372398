<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="contacts__img">
					<img v-if="order.instrument.logo" :src="order.instrument.logo" alt="Profile picture" />
					<img v-else src="../../assets/img/profile.png" alt="Profile picture" />
				</div>
				<div class="info__primary">
					<p class="info__email capital">{{ order.instrument.name }}</p>
				</div>
			</div>
			<p class="info__email capital">{{ fullName }}</p>
			<p class="info__address capital">{{ order.payment }}</p>
			<p class="info__address">{{ order.amount | currencyFormatter(order.currency) }}</p>
			<p class="info__address">{{ order.quantity }}</p>
			<p class="info__address">{{ order.status }}</p>
			<p>
				<router-link
					:to="{
						name: 'order-detail',
						params: { id: order.id }
					}"
					class="btn btn__primary"
					id="orderDetail"
				>
					View Details
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'orderItem',
	props: {
		orderData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			order: this.orderData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		fullName() {
			return `${this.order.user.firstname} ${this.order.user.lastname}`;
		}
	},
	filters: {
		currencyFormatter(val, currency) {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
				minimumFractionDigits: 2
			});
			return formatter.format(val);
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 40px 2.2fr 1.3fr 1fr 1fr 1fr 1fr 1fr 0.8fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
.capital {
	text-transform: capitalize;
}
</style>

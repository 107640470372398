<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="info__primary">
					<p class="info__email capital">{{ saving.name }}</p>
				</div>
			</div>
			<p class="info__email capital">{{ fullName }}</p>
			<p class="info__address capital">&#8358; {{ saving.amount }}</p>
			<p class="info__address capital">&#8358; {{ saving.balance }}</p>
			<p class="info__address capital" v-if="saving.is_recurrent === 1">
				Automated
			</p>
			<p class="info__address capital" v-else>
				Non Automated
			</p>
			<p class="info__address capital" v-if="saving.is_paused === 1">
				Paused
			</p>
			<p class="info__address capital" v-else>
				Active
			</p>
			<p class="info__address capital">{{ saving.payment }}</p>
			<p>
				<router-link
					:to="{
						name: 'saving-detail',
						params: { id: saving.id }
					}"
					class="btn btn__primary"
					id="savingDetail"
				>
					View Details
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'savingItem',
	props: {
		savingData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			saving: this.savingData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		fullName() {
			return `${this.saving.user.firstname} ${this.saving.user.lastname}`;
		}
	},
	filters: {
		currencyFormatter(val, currency) {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
				minimumFractionDigits: 2
			});
			return formatter.format(val);
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 20px 1fr 1.4fr 0.5fr 0.5fr 1fr 0.7fr 1fr 0.6fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
.capital {
	text-transform: capitalize;
}
</style>

<template>
	<div>
		<template v-if="loadingSavings">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header" v-if="orderRoute">
				<div class="main__primary">
					<h3>Saving</h3>
				</div>
				<div class="form__search-wrapper">
					<label class="form__label" for="search-user">
						<input
							id="search-user"
							class="form-control form__input form__search"
							type="search"
							v-model="query"
							@keyup.enter="search"
							placeholder="Type to search for an Saving"
						/>
						<span class="form__search__icon">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<title>Search Icon</title>
								<path
									d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 
									9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 
									0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
									fill="#828282"
								/>
							</svg>
						</span>
					</label>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts__body">
						<template v-if="savingsLoaded && searchLoaded && savings.length > 0">
							<table class="table">
								<thead style="background: #ffa137;">
									<tr class="text-white font-weight-normal">
										<th>NAME</th>
										<th>SAVER NAME</th>
										<th>AMOUNT</th>
										<th>BALANCE</th>
										<th>TYPE</th>
										<th>STATUS</th>
										<th>PAYMENT</th>
										<th>ACTION</th>
									</tr>
								</thead>
								<tbody class="text-muted">
									<tr v-for="saving in savings" :key="saving.id">
										<td>{{ saving.name }}</td>
										<td>{{ saving.user.firstname }} {{ saving.user.lastname }}</td>
										<td>{{ saving.amount }}</td>
										<td>{{ saving.balance }}</td>
										<td>
											<p class="info__address capital" v-if="saving.is_recurrent === 1">
												Automated
											</p>
											<p class="info__address capital" v-else>
												Non Automated
											</p>
										</td>
										<td>
											<p class="info__address capital" v-if="saving.is_paused === 1">
												Paused
											</p>
											<p class="info__address capital" v-else>
												Active
											</p>
										</td>
										<td>{{ saving.payment }}</td>
										<td>
											<p>
												<router-link
													:to="{ name: 'saving-detail', params: { id: saving.id }}"
													class="btn btn__primary"
													id="savingDetail"
												>
													View Details
												</router-link>
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</template>
						<template v-else-if="!searchLoaded && getsearchOrders.length > 0">
							<SavingItem v-for="saving in getsearchOrders" :key="saving.id" :savingData="saving" />
						</template>
						<template v-else-if="!searchLoaded && getsearchOrders.length === 0">
							<p class="mt-5 pl-5">No Saving found</p>
						</template>
						<template v-else-if="savingsLoaded">
							<p class="mt-5 pl-5">No Saving found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading savings, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import SavingItem from './SavingItem';

export default {
	name: 'Saving',
	components: {
		Loader,
		Pagination,
		SavingItem
	},
	data() {
		return {
			savingsLoaded: false,
			loadingSavings: true,
			offset: 0,
			limit: 10,
			baseUrl: 'savings',
			query: '',
			searchLoaded: true,
			total: 0
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllSavings([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (this.$route.name === 'savings') {
				this.orderRoute = true;
			}
			if (value === 'success') {
				this.loading = false;
				this.loadingSavings = false;
				this.query = '';
				this.savingsLoaded = true;
				this.total = this.searchLoaded ? this.totalSavings : this.searchTotal;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllSavings([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			savings: 'getSavings',
			status: 'getStatus',
			totalSavings: 'getSavingsTotal'
		})
	},
	methods: {
		...mapActions(['fetchAllSavings', 'searchOrders'])
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 20px 1fr 1.1fr 0.5fr 0.5fr 0.7fr 0.7fr 1fr 0.6fr !important;
}
</style>
